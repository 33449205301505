<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
            <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="date_when_generator_was_supplied" class="col-form-label col-form-label-sm">Date When Generator Was Supplied  <span class="custom-required">*</span></label>
              <datepicker :format="customSuppliedDateFormat" v-model="date_when_generator_was_supplied" :class="errors.has('date_when_generator_was_supplied') ? 'error-border-color' : ''"  id="date_when_generator_was_supplied"  name="date_when_generator_was_supplied" placeholder="Select date" ></datepicker>
              <div class="text-danger" style="margin-top: 0.25rem; font-size: 0.75rem;" v-if="errors.has('date_when_generator_was_supplied')">Supplied date is required</div>
            </div>
            <div class="col-md-6">
              <label for="generator_brand" class="col-form-label col-form-label-sm">Generator Brand <span class="custom-required">*</span></label>
              <input type="text" v-model="generator_brand" v-validate="{ rules: { required:  true }, max: 191 }" name="generator_brand" id="generator_brand" class="form-control form-control-sm" placeholder="Enter generator brand">
              <div class="invalid-feedback">Generator brand is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="engine_brand" class="col-form-label col-form-label-sm">Engine Brand <span class="custom-required">*</span></label>
              <input type="text" v-model="engine_brand" v-validate="{ rules: { required:  true }, max: 191 }"  name="engine_brand" id="engine_brand" class="form-control form-control-sm" placeholder="Enter engine brand">
              <div class="invalid-feedback">Engine brand is required</div>
            </div>
            <div class="col-md-6">
              <label for="asset_tag_no" class="col-form-label col-form-label-sm">Asset Tag No.</label>
              <input type="text" v-model="asset_tag_no" v-validate="{ max: 191 }" name="asset_tag_no" id="asset_tag_no" class="form-control form-control-sm" placeholder="Enter asset tag no">
              <div class="invalid-feedback">Asset tag no. is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="model_no" class="col-form-label col-form-label-sm">Model No. <span class="custom-required">*</span></label>
              <input type="text" v-model="model_no" v-validate="{ rules: { required:  true }, max: 191 }"  name="model_no" id="model_no" class="form-control form-control-sm" placeholder="Enter model no">
              <div class="invalid-feedback">Model no. is required</div>
            </div>
            <div class="col-md-6">
              <label for="capacity_kva" class="col-form-label col-form-label-sm">Capacity Kva <span class="custom-required">*</span></label>
              <input type="text" v-model="capacity_kva" v-validate="{ rules: { required:  true }, max: 191 }" name="capacity_kva" id="capacity_kva" class="form-control form-control-sm" placeholder="Enter capacity kva">
              <div class="invalid-feedback">Capacity kva is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="output_kw" class="col-form-label col-form-label-sm">Output Kw <span class="custom-required">*</span></label>
              <input type="text" v-model="output_kw" v-validate="{ rules: { required:  true }, max: 191 }"  name="output_kw" id="output_kw" class="form-control form-control-sm" placeholder="Enter output kw"/>
              <div class="invalid-feedback">Output kw is required</div>
            </div>
            <div class="col-md-6">
              <label for="rpm" class="col-form-label col-form-label-sm">Rpm <span class="custom-required">*</span></label>
              <input type="text" v-model="rpm" v-validate="{ rules: { required:  true }, max: 191 }"  name="rpm" id="rpm" class="form-control form-control-sm" placeholder="Enter rpm"/>
              <div class="invalid-feedback">Rpm is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="ats" class="col-form-label col-form-label-sm">Ats <span class="custom-required">*</span></label>
              <input type="text" v-model="ats" v-validate="{ rules: { required:  true }, max: 191 }"  name="ats" id="ats" class="form-control form-control-sm" placeholder="Enter ats">
              <div class="invalid-feedback">Ats is required</div>
            </div>
            <div class="col-md-6">
              <label for="fuel_pressure_gauge" class="col-form-label col-form-label-sm">Fuel Pressure Gauge <span class="custom-required">*</span></label>
              <input type="text" v-model="fuel_pressure_gauge" v-validate="{ rules: { required:  true }, max: 191 }"  name="fuel_pressure_gauge" id="fuel_pressure_gauge" class="form-control form-control-sm" placeholder="Enter fuel pressure gauge">
              <div class="invalid-feedback">Fuel pressure gauge is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="air_pressure_gauge" class="col-form-label col-form-label-sm">Air Pressure Gauge <span class="custom-required">*</span></label>
              <input type="text" v-model="air_pressure_gauge" v-validate="{ rules: { required:  true }, max: 191 }"  name="air_pressure_gauge" id="air_pressure_gauge" class="form-control form-control-sm" placeholder="Enter air pressure gauge">
              <div class="invalid-feedback">Air pressure gauge is required</div>
            </div>
            <div class="col-md-6">
              <label for="temperature_gauge" class="col-form-label col-form-label-sm">Temperature Gauge <span class="custom-required">*</span></label>
              <input type="text" v-model="temperature_gauge" v-validate="{ rules: { required:  true }, max: 191  }"  name="temperature_gauge" id="temperature_gauge" class="form-control form-control-sm" placeholder="Enter temperature gauge">
              <div class="invalid-feedback">Temperature gauge is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="fuel_indicator" class="col-form-label col-form-label-sm">Fuel Indicator <span class="custom-required">*</span></label>
              <input type="text" v-model="fuel_indicator" v-validate="{ rules: { required:  true }, max: 191  }"  name="fuel_indicator" id="fuel_indicator" class="form-control form-control-sm" placeholder="Enter fuel indicator">
              <div class="invalid-feedback">Fuel indicator is required</div>
            </div>
            <div class="col-md-6">
              <label for="hub_id" class="col-form-label col-form-label-sm">Hub <span class="custom-required">*</span></label>
              <select v-model="hub_id" v-validate="{ rules: { required:  true }}" id="hub_id" name="hub_id" class="form-control form-control-sm">
                <option selected  :value="''">Select a Hub</option>
                <option v-for="(hub, index) in hubs" :key="index" :value="hub.id">{{ hub.name }}</option>
              </select>
              <div class="invalid-feedback">Hub is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="generator_image" class="col-form-label col-form-label-sm">Generator Image <span style="font-weight: 100;">*Previous file <a :href="generator_image_url" target="_blank">click here</a></span></label>
              <input type="file" ref="generatorImage" id="generator_image" name="generator_image" @change="generatorImage()" accept=".jpg,.jpeg,.png" class="form-control form-control-sm"/>
              <div class="invalid-feedback">Generator image is required</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12">
          <a-button class="btn btn-sm btn-success float-left" :loading="submitLoading"  @click.prevent="update"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Update</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import { mapGetters } from 'vuex'

export default {
  name: 'create',
  components: { Datepicker },
  data() {
    return {
      hubs: [],
      submitLoading: false,
      generatorInfoId: '',
      date_when_generator_was_supplied: '',
      generator_brand: '',
      engine_brand: '',
      asset_tag_no: '',
      model_no: '',
      capacity_kva: '',
      output_kw: '',
      rpm: '',
      ats: '',
      fuel_pressure_gauge: '',
      air_pressure_gauge: '',
      temperature_gauge: '',
      fuel_indicator: '',
      generator_image: '',
      generator_image_url: '',
      hub_id: '',
      validation_errors: {},
      show: false,
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  mounted() {
    this.edit()
    this.getCodes()
  },
  methods: {
    customSuppliedDateFormat(date) {
      const suppliedDate = moment(date).format('YYYY-MM-DD')
      this.date_when_generator_was_supplied = suppliedDate
      return suppliedDate
    },
    getCodes() {
      apiClient.get('/api/generator-info/codes')
        .then(response => {
          this.hubs = response.data.hubs
        })
        .catch(error => {
          console.log(error)
        })
    },
    edit() {
      this.loading = true
      const generatorInfoId = this.$route.params.generator_info_id
      apiClient.get('api/generator-info/edit/' + generatorInfoId).then(response => {
        this.loading = false
        if (!response.data.error) {
          const data = response.data.generator_info
          this.generatorInfoId = data.id
          this.date_when_generator_was_supplied = data.date_when_generator_was_supplied
          this.generator_brand = data.generator_brand
          this.engine_brand = data.engine_brand
          this.asset_tag_no = data.asset_tag_no
          this.model_no = data.model_no
          this.capacity_kva = data.capacity_kva
          this.output_kw = data.output_kw
          this.rpm = data.rpm
          this.ats = data.ats
          this.fuel_pressure_gauge = data.fuel_pressure_gauge
          this.air_pressure_gauge = data.air_pressure_gauge
          this.temperature_gauge = data.temperature_gauge
          this.fuel_indicator = data.fuel_indicator
          this.generator_image_url = data.generator_image_url
          this.hub_id = data.hub_id
        } else {
          this.$notification.error({
            message: response.data.message,
          })
        }
      })
        .catch(error => {
          console.log(error)
        })
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          console.log(this.generator_image)
          this.submitLoading = true
          const formData = new FormData()
          formData.append('date_when_generator_was_supplied', this.date_when_generator_was_supplied)
          formData.append('generator_brand', this.generator_brand)
          formData.append('engine_brand', this.engine_brand)
          formData.append('asset_tag_no', this.asset_tag_no)
          formData.append('model_no', this.model_no)
          formData.append('capacity_kva', this.capacity_kva)
          formData.append('output_kw', this.output_kw)
          formData.append('rpm', this.rpm)
          formData.append('ats', this.ats)
          formData.append('fuel_pressure_gauge', this.fuel_pressure_gauge)
          formData.append('air_pressure_gauge', this.air_pressure_gauge)
          formData.append('temperature_gauge', this.temperature_gauge)
          formData.append('fuel_indicator', this.fuel_indicator)
          formData.append('generator_image', this.generator_image)
          formData.append('hub_id', this.hub_id)
          apiClient.post('/api/generator-info/update/' + this.generatorInfoId, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } })
            .then(response => {
              this.submitLoading = false
              if (!response.data.error) {
                this.$notification.success({
                  message: response.data.message,
                })
                this.$router.push({ name: 'generatorInfoList' })
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
              }
            })
            .catch(error => {
              this.submitLoading = false
              this.validation_errors = error.response.data.errors
              this.show = true
              this.hide()
            })
        }
      })
    },
    generatorImage() {
      this.generator_image = this.$refs.generatorImage.files[0]
      if (this.generator_image.size > 3072000) {
        this.$notification.error({
          message: 'File size not greater then 3 MB',
        })
        this.generator_image = ''
      }
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
    resetForm() {
      //
    },
  },
}
</script>

<style scoped>
.error-border-color input{
  border: 1px solid #dc3545 !important;
}
.error-border-color{
  border: 1px solid #dc3545 !important;
}
.error{
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.borderColor{
  border: 1px solid red !important;
  border-radius:4px !important;
}
</style>
